import Rellax from "rellax";
import { nextTick } from "vue";
export default {
  updated(el, { value: directiveData }) {
    if (utilities.isSSR()) {
      return;
    }
    if (el.rellax) {
      el.rellax.destroy();
    }

    // update rellax conf
    const rellax = new Rellax(el, directiveData);
    el.rellax = rellax;

    nextTick(() => {
      el.rellax.refresh();
    });
  },
  mounted(el, { value: directiveData }) {
    if (utilities.isSSR()) {
      return;
    }

    if (el.rellax) {
      el.rellax.destroy();
    }
    // create rellax and run
    const rellax = new Rellax(el, directiveData);
    el.rellax = rellax;

    nextTick(() => {
      el.rellax.refresh();
    });
  },
  beforeUnmount(el) {
    if (utilities.isSSR()) {
      return;
    }
    if (el.rellax) {
      el.rellax.destroy();
    }
  },
};
